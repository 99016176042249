$(document).ready(function() {
    $('.burger').click(function() {
        $('nav').toggleClass('on');
        return false;
    });
    $('#carousel').carousel({
        interval: false
    });
    var hash = window.location.hash;
    if(hash=='#etape2') {
        raz_slide();
        raz_slide_art();
        $('#carousel').carousel(1);
    }
    $('#carousel').on('slide.bs.carousel', function (e) {
        raz_slide();
        raz_slide_art();
    });
    $('#carousel').on('slid.bs.carousel', function (e) {
        var_index = $('.carousel-item.active').attr('id');
        if(var_index == 'item1') {
        }
        if(var_index == 'item2') {
            $('.photo').addClass('on');
            $('.passeport').addClass('on');
        }
        if(var_index == 'item3') {
            $('.diapo-h').addClass('on');
            $('.diapo-b').addClass('on');
        }
        if(var_index == 'item4') {
            $('.camembert').addClass('on');
            $('.polaroid-art-h').addClass('on');
        }
        if(var_index == 'art-item1') {
            $('.polaroid-art-1').addClass('on');
            $('.pressoir').addClass('on');
            $('.flute-droite').addClass('on');
        }
        if(var_index == 'art-item2') {
            $('.polaroid-art-2').addClass('on');
            $('.bouteille').addClass('on');
            $('.camembert').addClass('on');
            $('.formule').addClass('on');
        }
    });

    // var x, y, top, left, down;
    // $(".cuvees").mousedown(function(e){
    //     e.preventDefault();
    //     down=true;
    //     x=e.pageX;
    //     y=e.pageY;
    //     top=$(this).scrollTop();
    //     left=$(this).scrollLeft();
    // });
    // $(".cuvees a.carousel-control-prev").on( "click", function() {
    //     var position = $(".cuvees").scrollLeft();
    //     $(".cuvees").animate({
    //         scrollLeft: position - "25%"
    //     }, 400);
    // });
    // $(".cuvees a.carousel-control-next").on( "click", function() {
    //     var position = $(".cuvees").scrollLeft();
    //     $(".cuvees").animate({
    //             scrollLeft: position + "25%"
    //         }, 400);
    // });
    // $("body").mousemove(function(e){
    //     if(down){
    //         var newX=e.pageX;
    //         var newY=e.pageY;
    //         $(".cuvees").scrollTop(top-newY+y);
    //         $(".cuvees").scrollLeft(left-newX+x);
    //     }
    // });
    // $("body").mouseup(function(e){down=false;});

    $('.ico-oeil').hover(function() {
        raz_cuvee();
        $(".oeil").addClass('on');
        $(".ico-oeil").addClass('on');
    });
    $('.ico-nez').hover(function() {
        raz_cuvee();
        $(".nez").addClass('on');
        $(".ico-nez").addClass('on');
    });
    $('.ico-bouche').hover(function() {
        raz_cuvee();
        $(".bouche").addClass('on');
        $(".ico-bouche").addClass('on');
    });
    $('.ico-accord').hover(function() {
        raz_cuvee();
        $(".accord").addClass('on');
        $(".ico-accord").addClass('on');
    });
});

function raz_cuvee() {
    $('.oeil').removeClass('on');
    $('.oeil').addClass('off');
    $('.nez').removeClass('on');
    $('.nez').addClass('off');
    $('.bouche').removeClass('on');
    $('.bouche').addClass('off');
    $('.accord').removeClass('on');
    $('.accord').addClass('off');
    
    $(".ico-oeil").removeClass('on');
    $(".ico-nez").removeClass('on');
    $(".ico-bouche").removeClass('on');
    $(".ico-accord").removeClass('on');
}
function raz_slide() {
    $('.passeport').removeClass('on');
    $('.photo').removeClass('on');
    $('.diapo-h').removeClass('on');
    $('.diapo-b').removeClass('on');
    $('.camembert').removeClass('on');
    $('.polaroid-art-h').removeClass('on');
}
function raz_slide_art() {
    $('.polaroid-art-1').removeClass('on');
    $('.pressoir').removeClass('on');
    $('.flute-droite').removeClass('on');
    $('.bouteille').removeClass('on');
    $('.formule').removeClass('on');
    $('.polaroid-art-2').removeClass('on');
}